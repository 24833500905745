<!------   DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>
<!-- Enllaç canònic -->
<div *ngIf="canonicalLink">
  <link rel="canonical" appMoveToHead [attr.href]="canonicalLink | safeUrl" />
</div>
<!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
<meta appMoveToHead [attr.content]="metaContentKeywords |translate" name="keywords">
<meta appMoveToHead [attr.content]="metaContentDescription |translate" name="description">
<!-- FACEBOOK cal modificar el meta locale llengua dinàmic-->
<meta property="og:locale" appMoveToHead [attr.content]="ogLocale |translate">
<!-- Maximum 35 characters -->
<meta property="og:title" appMoveToHead [attr.content]="newTitle |translate">
<!-- Maximum 65 characters -->
<meta property="og:description" appMoveToHead [attr.content]="metaContentDescription |translate">
<meta property="og:url" appMoveToHead [attr.content]="canonicalLink | safeUrl">
<meta property="og:site_name" appMoveToHead [attr.content]="ogSiteName |translate">

<!-- TWITTER -->
<meta name="twitter:description" appMoveToHead [attr.content]="metaContentDescription |translate">
<meta name="twitter:title" appMoveToHead [attr.content]="newTitle |translate">
<!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
<link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="ca" />
<link rel="alternate" appMoveToHead [attr.href]="esLink | safeUrl" hreflang="es" />
<link rel="alternate" appMoveToHead [attr.href]="enLink | safeUrl" hreflang="en" />
<link rel="alternate" appMoveToHead [attr.href]="frLink | safeUrl" hreflang="fr" />
<link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="x-default" />
<!------   END DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>

<div class="container">
  <div class="row justify-content-center padding-menu titulos" id="titulos">
    <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center pb-5">
      <h1 class="objetivos-tl animated inLeft titol-IV">{{'grups-particulars.titol' | translate}}</h1>
      <p class="pt-2 animated inRight subtitol-IV">{{'grups-particulars.subtitol' | translate}}</p>
      <a target="_blank" href="{{'visites.cataleg.samara-link' | translate}}"><br><br>
        <label class="reserva">{{'cataleg.button' | translate}}</label></a>
    </div>
  </div>

  <!-- VISITES A MIDA -->
  <section class="row section-mida" id="section-mida">
    <div class="col-12 col-md-10 offset-md-1 pt-5">
      <div class="row section-pc">
        <div class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
          <h3 class="question">{{ 'home.titols.alacarta' | translate}}</h3>
          <p class="tx-mida">{{ 'home.alacarta.subtitol' | translate}}</p>
        </div>
        <div class="col-12 col-xl-10 offset-xl-1">
          <div class="row">
            <div class="col-12 col-md-4 text-center animated inLeft">
              <img class="icon-3" src="assets/icons/grup.png">
              <p class="prfs-mida">{{ 'home.alacarta.grups' | translate}}</p>
            </div>
            <div class="col-12 col-md-4 text-center animated inTop">
              <img class="icon-3" src="assets/icons/calendari.png">
              <p class="prfs-mida">{{ 'home.alacarta.horaris' | translate}}</p>
            </div>
            <div class="col-12 col-md-4 text-center animated inRight">
              <img class="icon-3" src="assets/icons/smile.png">
              <p class="prfs-mida">{{ 'home.alacarta.guies' | translate}}</p>
            </div>
            <div class="col-12 text-center escriu animated inBottom">
              <a (click)="clickTimestamp('booking', 'https://solsonaexperience/contacte')" [routerLink]="['/',currentLang,'contacte']" routerLinkActive="active"><input class="vst-button" type="button"
                  value="{{ 'home.alacarta.escriu' | translate}}"></a><br><br>
              <b><a [routerLink]="['/',currentLang,'politica']" routerLinkActive="active"> {{'visites.politica.samara' | translate}}</a></b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- end visites a mida -->

  <!-- ACTIVITATS GRUPS -->
  <section class="row section-experiencies" id="section-experiencies">
    <div class="col-md-10 col-lg-10 offset-md-1 offset-lg-1 col-xs-12 col-sm-12 col-12 pb-5 mobile-card-container">
      <div class="row flex-wrap pt-5">
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'solsona-monumental']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/solsona-monumental-mini.jpg" alt="solsona monumental">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.solsona-monumental.titol' | translate}}</h3>
            <p>{{'visites.solsona-monumental.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'solsona-monumental']" routerLinkActive="active">
            <label class="reserva">{{'visites.solsona-monumental.button' | translate}}</label>
          </a>
        </div>
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'santuari-miracle']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/santuari-miracle-mini.jpg" alt="santuari del miracle">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.santuari-miracle.titol' | translate}}</h3>
            <p>{{'visites.santuari-miracle.subtitol-curt' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'santuari-miracle']" routerLinkActive="active">
            <label class="reserva">{{'visites.santuari-miracle.button' | translate}}</label>
          </a>
        </div>
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'olius']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/olius-mini.jpg" alt="sant esteve olius">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.olius.titol' | translate}}</h3>
            <p>{{'visites.olius.subtitol-curt' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'olius']" routerLinkActive="active">
            <label class="reserva">{{'visites.olius.button' | translate}}</label>
          </a>
        </div>
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'gegants-carnaval-solsona']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/gegants-carnaval-solsona-mini.jpg" alt="gegants carnaval Solsona">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.gegants-carnaval-solsona.titol' | translate}}</h3>
            <p>{{'visites.gegants-carnaval-solsona.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'gegants-carnaval-solsona']" routerLinkActive="active">
            <label class="reserva">{{'visites.gegants-carnaval-solsona.button' | translate}}</label>
          </a>
        </div>
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'pou-gel']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/pou-gel-mini.jpg" alt="pou gel Solsona">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.pou-gel.titol' | translate}}</h3>
            <p>{{'visites.pou-gel.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'pou-gel']" routerLinkActive="active">
            <label class="reserva">{{'visites.pou-gel.button' | translate}}</label>
          </a>
        </div>
        <!--<div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'nocturnes-olius']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/nocturnes-olius-mini.jpg" alt="olius">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.nocturnes-olius.titol' | translate}}</h3>
            <p>{{'visites.nocturnes-olius.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'nocturnes-olius']" routerLinkActive="active">
            <label class="reserva">{{'visites.nocturnes-olius.button' | translate}}</label>
          </a>
        </div>-->
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'santuari-pinos-torre-ardevol']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/santuari-pinos-torre-ardevol-mini.jpg" alt="necròpolis llor solsonès">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.santuari-pinos-torre-ardevol.titol' | translate}}</h3>
            <p></p>
          </div>
          <a [routerLink]="['/',currentLang,'santuari-pinos-torre-ardevol']" routerLinkActive="active">
            <label class="reserva">{{'visites.santuari-pinos-torre-ardevol.button' | translate}}</label>
          </a>
        </div>
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'nocturna-carnaval']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/nocturna-carnaval-mini.jpg" alt="necròpolis llor solsonès">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.nocturna-carnaval.titol' | translate}}</h3>
            <p></p>
          </div>
          <a [routerLink]="['/',currentLang,'nocturna-carnaval']" routerLinkActive="active">
            <label class="reserva">{{'visites.nocturna-carnaval.button' | translate}}</label>
          </a>
        </div>
        <!--<div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'busa']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/busa-mini.jpg" alt="Serra de Busa">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.busa.titol' | translate}}</h3>
            <p>{{'visites.busa.subtitol-curt' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'busa']" routerLinkActive="active">
            <label class="reserva">{{'visites.busa.button' | translate}}</label>
          </a>
        </div>-->
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'catedral']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/catedral-mini.jpg" alt="catedral solsona">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.catedral.titol' | translate}}</h3>
            <p>{{'visites.catedral.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'catedral']" routerLinkActive="active">
            <label class="reserva">{{'visites.catedral.button' | translate}}</label>
          </a>
        </div>
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'torre-vallferosa']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/torre-vallferosa-mini.jpg" alt="torre de Vallferosa">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.torre-vallferosa.titol' | translate}}</h3>
            <p></p>
          </div>
          <a [routerLink]="['/',currentLang,'torre-vallferosa']" routerLinkActive="active">
            <label class="reserva">{{'visites.torre-vallferosa.button' | translate}}</label>
          </a>
        </div>
        <!-- <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'quarto-gegants']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/quarto-gegants-mini.jpg" alt="quarto gegants Solsona">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.quarto-gegants.titol' | translate}}</h3>
            <p>{{'visites.quarto-gegants.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'quarto-gegants']" routerLinkActive="active">
            <label class="reserva">{{'visites.quarto-gegants.button' | translate}}</label>
          </a>
        </div> -->

        <!--<div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'campanar']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/campanar-mini.jpg" alt="campanar catedral Solsona">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.campanar.titol' | translate}}</h3>
            <p>{{'visites.campanar.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'campanar']" routerLinkActive="active">
            <label class="reserva">{{'visites.campanar.button' | translate}}</label>
          </a>
        </div>-->
        <!-- <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'tasta-solsona']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/tasta-solsona-mini.jpg" alt="Tasta Solsona">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.tasta-solsona.titol' | translate}}</h3>
            <p>{{'visites.tasta-solsona.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'tasta-solsona']" routerLinkActive="active">
            <label class="reserva">{{'visites.tasta-solsona.button' | translate}}</label>
          </a>
        </div> -->
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'nucli-antic']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/nucli-antic-mini.jpg" alt="nucli antic solsona">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.nucli-antic.titol' | translate}}</h3>
            <p>{{'visites.nucli-antic.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'nucli-antic']" routerLinkActive="active">
            <label class="reserva">{{'visites.nucli-antic.button' | translate}}</label>
          </a>
        </div>
        <!-- <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'museu-solsona']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/museu-solsona-mini.jpg" alt="Museu Diocesà Solsona">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.museu-solsona.titol' | translate}}</h3>
            <p>{{'visites.museu-solsona.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'museu-solsona']" routerLinkActive="active">
            <label class="reserva">{{'visites.museu-solsona.button' | translate}}</label>
          </a>
        </div> -->
        <!-- <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'globus-pirineu']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/globus-pirineu-mini.jpg" alt="Vol Globus Pirineu">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.globus-pirineu.titol' | translate}}</h3>
            <p>{{'visites.globus-pirineu.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'globus-pirineu']" routerLinkActive="active">
            <label class="reserva">{{'visites.globus-pirineu.button' | translate}}</label>
          </a>
        </div> -->
        <!-- <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'celler-miracle']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/celler-miracle-mini.jpg" alt="Celler del Miracle">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.celler-miracle.titol' | translate}}</h3>
            <p>{{'visites.celler-miracle.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'celler-miracle']" routerLinkActive="active">
            <label class="reserva">{{'visites.celler-miracle.button' | translate}}</label>
          </a>
        </div> -->
        <!-- faltarà SANTUARI MIRACLE, SANT LLORENÇ, SANT PERE GRAUDESCALES, SANT PERE MATAMARGÓ, ECOMUSEU VALL D'ORA, SALÍ CAMBRILS -->
      </div>
    </div>
  </section>
  <!-- end activtitats grups -->

</div>