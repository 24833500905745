<!------   DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>
<!-- Enllaç canònic -->
<div *ngIf="canonicalLink">
  <link rel="canonical" appMoveToHead [attr.href]="canonicalLink | safeUrl" />
</div>
<!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
<meta appMoveToHead [attr.content]="metaContentKeywords |translate" name="keywords">
<meta appMoveToHead [attr.content]="metaContentDescription |translate" name="description">
<!-- FACEBOOK cal modificar el meta locale llengua dinàmic-->
<meta property="og:locale" appMoveToHead [attr.content]="ogLocale |translate">
<!-- Maximum 35 characters -->
<meta property="og:title" appMoveToHead [attr.content]="newTitle |translate">
<!-- Maximum 65 characters -->
<meta property="og:description" appMoveToHead [attr.content]="metaContentDescription |translate">
<meta property="og:url" appMoveToHead [attr.content]="canonicalLink | safeUrl">
<meta property="og:site_name" appMoveToHead [attr.content]="ogSiteName |translate">

<!-- TWITTER -->
<meta name="twitter:description" appMoveToHead [attr.content]="metaContentDescription |translate">
<meta name="twitter:title" appMoveToHead [attr.content]="newTitle |translate">
<!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
<link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="ca" />
<link rel="alternate" appMoveToHead [attr.href]="esLink | safeUrl" hreflang="es" />
<link rel="alternate" appMoveToHead [attr.href]="enLink | safeUrl" hreflang="en" />
<link rel="alternate" appMoveToHead [attr.href]="frLink | safeUrl" hreflang="fr" />
<link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="x-default" />
<!------   END DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>

<div class="container">
  <div class="row justify-content-center padding-menu titulos" id="titulos">
    <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center pb-5">
      <h1 class="objetivos-tl animated inLeft titol-IV">{{'agencies.titol' | translate}}</h1>
      <p class="pt-2 animated inRight subtitol-IV">{{'agencies.subtitol' | translate}}</p>
    </div>
  </div>

  <!-- VISITES A MIDA -->
  <section class="row section-mida" id="section-mida">
    <div class="col-12 col-md-10 offset-md-1 pt-5">
      <div class="row section-pc">
        <div class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
          <h3 class="question">{{ 'agencies.titols.alacarta' | translate}}</h3>
          <p class="tx-mida">{{ 'agencies.alacarta.subtitol' | translate}}</p>
        </div>
        <div class="col-12 col-xl-10 offset-xl-1">
          <div class="row">
            <div class="col-12 col-md-4 text-center animated inLeft">
              <img class="icon-3" src="assets/icons/grup.png">
              <p class="prfs-mida">{{ 'agencies.alacarta.grups' | translate}}</p>
            </div>
            <div class="col-12 col-md-4 text-center animated inTop">
              <img class="icon-3" src="assets/icons/calendari.png">
              <p class="prfs-mida">{{ 'agencies.alacarta.horaris' | translate}}</p>
            </div>
            <div class="col-12 col-md-4 text-center animated inRight">
              <img class="icon-3" src="assets/icons/smile.png">
              <p class="prfs-mida">{{ 'agencies.alacarta.guies' | translate}}</p>
            </div>
            <div class="col-12 text-center escriu animated inBottom">
              <a [routerLink]="['/',currentLang,'contacte']" (click)="clickTimestamp('booking', 'https://solsonaexperience/contacte')" routerLinkActive="active"><input class="vst-button button-IV" type="button"
                  value="{{ 'agencies.alacarta.pressupost' | translate}}"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- end visites a mida -->

  <!-- DESTACATS AGÈNCIES -->
  <section class="row section-grups" id="section-grups">
    <div class="col-md-10 col-lg-10 offset-md-1 offset-lg-1 col-xs-12 col-sm-12 col-12 pb-5 mobile-card-container">
      <div class="row justify-content-center animated inTop">
        <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center mb-4">
          <h2 class="titol">{{ 'agencies.destacats.titol' | translate}}</h2>
          <p class="prfs-mida">{{ 'agencies.destacats.subtitol' | translate}}</p>
        </div>
      </div>

      <div class="row flex-nowrap flex-lg-wrap">
        <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'solsona-monumental']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.solsona-monumental.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/solsona-monumental-mini.jpg" alt="solsona monumental">
          </a>
        </div>
        <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'santuari-miracle']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.santuari-miracle.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/santuari-miracle-mini.jpg" alt="santuari del miracle">
          </a></div>
        <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'olius']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.olius.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/olius-mini.jpg" alt="sant esteve olius">
          </a></div>
        <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'pou-gel']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.pou-gel.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/pou-gel-mini.jpg" alt="Pou gel Solsona">
          </a>
        </div>
        <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'gegants-carnaval-solsona']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.gegants-carnaval-solsona.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/gegants-carnaval-solsona-mini.jpg" alt="gegants carnaval solsona">
          </a> </div>
        <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'catedral']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.catedral.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/catedral-mini.jpg" alt="catedral solsona">
          </a> </div>
      </div>
      <div class="row oculta-mobil">
        <div class="col-12">
          <div class="text-center pb-4 pt-2">
            <a [routerLink]="['/',currentLang,'grups-particulars']" routerLinkActive="active">{{ 'home.veure-opcions' | translate}}</a>
          </div>
        </div>
      </div>
      <div class="row mostra-mobil">
        <div class="col-12">
          <div class="text-center pb-4 pt-2">
            <a [routerLink]="['/',currentLang,'grups-particulars']" routerLinkActive="active">
              <label class="reserva">{{ 'home.veure-opcions' | translate}}</label>
            </a></div>
        </div>
      </div>
    </div>
  </section>
  <!-- end destacats agències -->

  <!-- agencies confien -->
  <!-- <div class="row section-visitants justify-content-center agencias" id="agencias">
    <div class="col-12 col-md-9 col-lg-7 mx-auto">
      <div class="row justify-content-center a-center pt-5">
        <div class="col-12">
          <h3 class="confian animated inTop">{{'agencies.titols.confien' | translate}}</h3>
        </div>
      </div>
      <div class="row justify-content-center pt-5 pb-5">
        <div class="col-8 col-xs-5 col-sm-5 col-md-4 col-lg-4 mx-auto">
          <img src="assets/logos/agencies/camps.png" class="img-responsive animated inLeft" alt="">
        </div>
        <div class="col-8 col-xs-5 col-sm-5 col-md-4 col-lg-4 mx-auto">
          <img src="assets/logos/agencies/electra.png" class="img-responsive animated inBottom" alt="">
        </div>
        <div class="col-8 col-xs-5 col-sm-5 col-md-4 col-lg-4 mx-auto">
          <img src="assets/logos/agencies/farmacia-optica.png" class="img-responsive animated inRight" alt="">
        </div>
      </div>
    </div>
  </div> -->
  <!-- end agencies confien -->

</div>