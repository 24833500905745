<!-- header-start -->
<header>
  <div class="header-area ">
    <div id="sticky-header" class="main-header-area">
      <div class="container-fluid">
        <div class="header_bottom_border">
          <div class="row align-items-center">
            <div class="col-xl-3 col-lg-3 col-2 mx-auto">
              <div class="main-menu main-lang">
                <nav class="menu-lang">
                  <ul id="navigationLang">
                    <li><a class="pointer">{{ 'header.canviar-idioma' | translate}}</a>
                      <ul class="submenu">
                        <li><a class="pointer" (click)="changeLang('ca')">{{ 'language.ca' | translate}}</a></li>
                        <li><a class="pointer" (click)="changeLang('es')">{{ 'language.es' | translate}}</a></li>
                        <li><a class="pointer" (click)="changeLang('en')">{{ 'language.en' | translate}}</a></li>
                        <li><a class="pointer" (click)="changeLang('fr')">{{ 'language.fr' | translate}}</a></li>
                      </ul>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-8 mx-auto">
              <div class="logo-img">
                <a [routerLink]="['/',currentLang,'home']" routerLinkActive="active">
                  <img src="assets/logos/logo-solsona-experience.png" alt="Logo Solsona Experience">
                </a>
              </div>
            </div>
            <div class="col-2 d-block d-lg-none d-xl-none">

              <div class="mobile_menu">
                <div class="mobile-menu-btn">
                  <div class="btn-line"></div>
                  <div class="btn-line"></div>
                  <div class="btn-line"></div>
                </div>
                <div id="lateral-wrap" class="mobile-menu-wrap">
                  <span id="close-btn">
                    <i class="fa fa-times"></i>
                  </span>
                  <nav class="mobileMenu">
                    <ul id="navigation">
                      <li><a [routerLink]="['/',currentLang,'visites-programades']" routerLinkActive="active">{{ 'header.visites-programades' | translate}}</a></li>
                      <li><a [routerLink]="['/',currentLang,'experiencies']" routerLinkActive="active">{{ 'header.experiencies' | translate}}</a></li>
                      <li><a [routerLink]="['/',currentLang,'grups-particulars']" routerLinkActive="active">{{ 'header.grups-particulars' | translate}}</a></li>
                      <li><a [routerLink]="['/',currentLang,'agencies']" routerLinkActive="active">{{ 'header.agencies' | translate}}</a></li>
                      <div class="mobile-menu-space"></div>
                      <li><a [routerLink]="['/',currentLang,'qui-som']" routerLinkActive="active">{{ 'header.qui-som' | translate}}</a></li>
                      <li><a [routerLink]="['/',currentLang,'missio-visio-valors']" routerLinkActive="active">{{ 'header.missio-visio-valors' | translate}}</a></li>
                      <!-- <li><a [routerLink]="['/',currentLang,'blog']" routerLinkActive="active">{{ 'header.blog' | translate}}</a></li> -->
                      <li><a [routerLink]="['/',currentLang,'avis-legal']" routerLinkActive="active">{{ 'header.avis-legal' | translate}}</a></li>
                      <div class="mobile-menu-space"></div>
                      <li><a [routerLink]="['/',currentLang,'contacte']" routerLinkActive="active">{{ 'header.contacte' | translate}}</a></li>
                    </ul>
                    <div class="social_wrap">
                      <div class="social_links social_links-IV">
                        <ul>
                          <li><a target="_blank" href="https://www.facebook.com/solsonaexperience/"> <i class="fa fa-facebook"></i> </a></li>
                          <li><a target="_blank" href="https://twitter.com/solsoexperience"> <i class="fa fa-twitter"></i> </a></li>
                          <li><a target="_blank" href="https://www.instagram.com/solsonaexperience/"> <i class="fa fa-instagram"></i> </a></li>
                          <li><a target="_blank" href="https://www.youtube.com/channel/UC-VtOu-FVkRMFJc2TiXC6_Q"> <i class="fa fa-youtube"></i> </a></li>
                        </ul>
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-lg-3 d-none d-lg-block sin-padding">
              <div class="social_wrap d-flex align-items-center justify-content-end">
                <div class="social_links social-links-IV d-none d-lg-block">
                  <ul>
                    <li><a [routerLink]="['/',currentLang,'contacte']" routerLinkActive="active">{{ 'header.contacte' | translate}}</a></li>
                    <li><a target="_blank" href="https://www.facebook.com/solsonaexperience/"> <i class="fa fa-facebook"></i> </a></li>
                    <li><a target="_blank" href="https://twitter.com/solsoexperience"> <i class="fa fa-twitter"></i> </a></li>
                    <li><a target="_blank" href="https://www.instagram.com/solsonaexperience/"> <i class="fa fa-instagram"></i> </a></li>
                    <li><a target="_blank" href="https://www.youtube.com/channel/UC-VtOu-FVkRMFJc2TiXC6_Q"> <i class="fa fa-youtube"></i> </a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mx-auto">
              <div class="main-menu-IV  d-none d-lg-block">
                <nav>
                  <ul id="navigation">
                    <li><a [routerLink]="['/',currentLang,'visites-programades']" routerLinkActive="active">{{ 'header.visites-programades' | translate}}</a></li>
                    <li><a [routerLink]="['/',currentLang,'experiencies']" routerLinkActive="active">{{ 'header.experiencies' | translate}}</a></li>
                    <li><a [routerLink]="['/',currentLang,'grups-particulars']" routerLinkActive="active">{{ 'header.grups-particulars' | translate}}</a></li>
                    <li><a [routerLink]="['/',currentLang,'agencies']" routerLinkActive="active">{{ 'header.agencies' | translate}}</a></li>
                    <li><a [routerLink]="['/',currentLang,'qui-som']" routerLinkActive="active">{{ 'header.qui-som' | translate}}</a></li>
                  </ul>
                </nav>
              </div>
            </div>
            <!-- <div class="seach_icon">
              <a data-toggle="modal" data-target="#exampleModalCenter" href="#">
                <i class="fa fa-search"></i>
              </a>
            </div> -->

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="whats">
    <div class="logo-whats">
      <a target="_blank" (click)="clickTimestamp('message', 'https://wa.me/34663668184')" href="https://wa.me/34663668184">
        <img src="assets/icons/whatsapp.png" alt="Logo Whatsapp">
      </a>
    </div>
  </div>
</header>
<!-- header-end -->

<!-- Modal SEARCH -->
<!-- <div class="modal fade custom_search_pop" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="serch_form">
          <input type="text" placeholder="Search">
          <button type="submit">search</button>
        </div>
      </div>
    </div>
  </div> -->
<!-- link that opens popup -->
