<!------   DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>
<!-- Enllaç canònic -->
<div *ngIf="canonicalLink">
  <link rel="canonical" appMoveToHead [attr.href]="canonicalLink | safeUrl" />
</div>
<!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
<meta appMoveToHead [attr.content]="metaContentKeywords |translate" name="keywords">
<meta appMoveToHead [attr.content]="metaContentDescription |translate" name="description">
<!-- FACEBOOK cal modificar el meta locale llengua dinàmic-->
<meta property="og:locale" appMoveToHead [attr.content]="ogLocale |translate">
<!-- Maximum 35 characters -->
<meta property="og:title" appMoveToHead [attr.content]="newTitle |translate">
<!-- Maximum 65 characters -->
<meta property="og:description" appMoveToHead [attr.content]="metaContentDescription |translate">
<meta property="og:url" appMoveToHead [attr.content]="canonicalLink | safeUrl">
<meta property="og:site_name" appMoveToHead [attr.content]="ogSiteName |translate">

<!-- TWITTER -->
<meta name="twitter:description" appMoveToHead [attr.content]="metaContentDescription |translate">
<meta name="twitter:title" appMoveToHead [attr.content]="newTitle |translate">
<!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
<link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="ca" />
<link rel="alternate" appMoveToHead [attr.href]="esLink | safeUrl" hreflang="es" />
<link rel="alternate" appMoveToHead [attr.href]="enLink | safeUrl" hreflang="en" />
<link rel="alternate" appMoveToHead [attr.href]="frLink | safeUrl" hreflang="fr" />
<link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="x-default" />
<!------   END DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>

<div class="container">
  <div class="row justify-content-center padding-menu titulos" id="titulos">
    <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center pb-5">
      <h1 class="objetivos-tl animated inLeft titol-IV">{{'experiencies.titol' | translate}}</h1>
      <p class="pt-2 animated inRight subtitol-IV">{{'experiencies.subtitol' | translate}}</p>
    </div>
  </div>
  <!-- SLIDER PRINCIPAL -->
  <div class="row justify-content-center padding-menu banner amaga-md-sm-xs" id="banner">
    <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center sin-padding animated inRight">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" style="background: url(assets/images/santuari-pinos-torre-ardevol.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.santuari-pinos-torre-ardevol.titol' | translate}}</h1>
                <p></p>
                <a [routerLink]="['/',currentLang,'santuari-pinos-torre-ardevol']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.santuari-pinos-torre-ardevol.button' | translate}}</label></a>
              </div>
            </div>
          </div>
          <div class="swiper-slide" style="background: url(assets/images/nocturna-carnaval.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.nocturna-carnaval.titol' | translate}}</h1>
                <p></p>
                <a [routerLink]="['/',currentLang,'nocturna-carnaval']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.nocturna-carnaval.button' | translate}}</label></a>
              </div>
            </div>
          </div>
          <!--<div class="swiper-slide" style="background: url(assets/images/nocturnes-olius.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.nocturnes-olius.titol' | translate}}</h1>
                <p>{{ 'visites.nocturnes-olius.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'nocturnes-olius']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.nocturnes-olius.button' | translate}}</label></a>
              </div>
            </div>
          </div>-->
          <div class="swiper-slide" style="background: url(assets/images/celler-miracle.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.celler-miracle.titol' | translate}}</h1>
                <p>{{ 'visites.celler-miracle.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'celler-miracle']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.celler-miracle.button' | translate}}</label></a>
              </div>
            </div>
          </div>

          <div class="swiper-slide" style="background: url(assets/images/senderisme-castellvell.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.senderisme-castellvell.titol' | translate}}</h1>
                <p>{{ 'visites.senderisme-castellvell.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'senderisme-castellvell']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.senderisme-castellvell.button' | translate}}</label></a>
              </div>
            </div>
          </div>
          <div class="swiper-slide" style="background: url(assets/images/busa.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.busa.titol' | translate}}</h1>
                <p>{{ 'visites.busa.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'busa']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.busa.button' | translate}}</label></a>
              </div>
            </div>
          </div>
          <!-- <div class="swiper-slide" style="background: url(assets/images/tasta-solsona.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.tasta-solsona.titol' | translate}}</h1>
                <p>{{ 'visites.tasta-solsona.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'tasta-solsona']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.tasta-solsona.button' | translate}}</label></a>
              </div>
            </div>
          </div> -->
          <div class="swiper-slide" style="background: url(assets/images/nucli-antic.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.nucli-antic.titol' | translate}}</h1>
                <p>{{ 'visites.nucli-antic.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'nucli-antic']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.nucli-antic.button' | translate}}</label></a>
              </div>
            </div>
          </div>
          <div class="swiper-slide" style="background: url(assets/images/catedral.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.catedral.titol' | translate}}</h1>
                <p>{{ 'visites.catedral.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'catedral']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.catedral.button' | translate}}</label></a>
              </div>
            </div>
          </div>
          <div class="swiper-slide" style="background: url(assets/images/a-la-carta.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.a-la-carta.titol' | translate}}</h1>
                <p>{{ 'visites.a-la-carta.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'a-la-carta']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.a-la-carta.button' | translate}}</label></a>
              </div>
            </div>
          </div>
          <div class="swiper-slide" style="background: url(assets/images/campanar.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.campanar.titol' | translate}}</h1>
                <p>{{ 'visites.campanar.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'campanar']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.campanar.button' | translate}}</label></a>
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </div>
  <!-- end slider principal -->


  <!-- EXPERIENCES -->
  <section class="row section-experiencies" id="section-experiencies">
    <div class="col-md-10 col-lg-10 offset-md-1 offset-lg-1 col-xs-12 col-sm-12 col-12 pb-5 mobile-card-container">

      <div class="row flex-wrap pt-5">
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'santuari-pinos-torre-ardevol']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/santuari-pinos-torre-ardevol-mini.jpg" alt="deu anys">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.santuari-pinos-torre-ardevol.titol' | translate}}</h3>
            <p></p>
          </div>
          <a [routerLink]="['/',currentLang,'santuari-pinos-torre-ardevol']" routerLinkActive="active">
            <label class="reserva">{{'visites.santuari-pinos-torre-ardevol.button' | translate}}</label>
          </a>
        </div>
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'nocturna-carnaval']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/nocturna-carnaval-mini.jpg" alt="Carnaval de Solsona">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.nocturna-carnaval.titol' | translate}}</h3>
            <p></p>
          </div>
          <a [routerLink]="['/',currentLang,'nocturna-carnaval']" routerLinkActive="active">
            <label class="reserva">{{'visites.nocturna-carnaval.button' | translate}}</label>
          </a>
        </div>
        <!--<div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'nocturnes-olius']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/nocturnes-olius-mini.jpg" alt="olius">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.nocturnes-olius.titol' | translate}}</h3>
            <p>{{'visites.nocturnes-olius.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'nocturnes-olius']" routerLinkActive="active">
            <label class="reserva">{{'visites.nocturnes-olius.button' | translate}}</label>
          </a>
        </div>-->
        <!-- <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'celler-miracle']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/celler-miracle-mini.jpg" alt="llor">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.celler-miracle.titol' | translate}}</h3>
            <p>{{'visites.celler-miracle.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'celler-miracle']" routerLinkActive="active">
            <label class="reserva">{{'visites.celler-miracle.button' | translate}}</label>
          </a>
        </div>-->
        <!--<div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'senderisme-castellvell']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/senderisme-castellvell-mini.jpg" alt="Castellvell">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.senderisme-castellvell.titol' | translate}}</h3>
            <p>{{'visites.senderisme-castellvell.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'senderisme-castellvell']" routerLinkActive="active">
            <label class="reserva">{{'visites.senderisme-castellvell.button' | translate}}</label>
          </a>
        </div> -->
        <!-- <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'busa']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/busa-mini.jpg" alt="Serra de Busa">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.busa.titol' | translate}}</h3>
            <p>{{'visites.busa.subtitol-curt' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'busa']" routerLinkActive="active">
            <label class="reserva">{{'visites.busa.button' | translate}}</label>
          </a>
        </div> -->
        <!-- <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'tasta-solsona']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/tasta-solsona-mini.jpg" alt="Tasta Solsona">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.tasta-solsona.titol' | translate}}</h3>
            <p>{{'visites.tasta-solsona.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'tasta-solsona']" routerLinkActive="active">
            <label class="reserva">{{'visites.tasta-solsona.button' | translate}}</label>
          </a>
        </div> -->
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'nucli-antic']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/nucli-antic-mini.jpg" alt="nucli antic solsona">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.nucli-antic.titol' | translate}}</h3>
            <p>{{'visites.nucli-antic.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'nucli-antic']" routerLinkActive="active">
            <label class="reserva">{{'visites.nucli-antic.button' | translate}}</label>
          </a>
        </div>
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'catedral']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/catedral-mini.jpg" alt="catedral solsona">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.catedral.titol' | translate}}</h3>
            <p>{{'visites.catedral.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'catedral']" routerLinkActive="active">
            <label class="reserva">{{'visites.catedral.button' | translate}}</label>
          </a>
        </div>
        <!--<div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'campanar']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/campanar-mini.jpg" alt="campanar catedral Solsona">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.campanar.titol' | translate}}</h3>
            <p>{{'visites.campanar.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'campanar']" routerLinkActive="active">
            <label class="reserva">{{'visites.campanar.button' | translate}}</label>
          </a>
        </div>-->
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'a-la-carta']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/a-la-carta-mini.jpg" alt="a la carta">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.a-la-carta.titol' | translate}}</h3>
            <p>{{'visites.a-la-carta.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'a-la-carta']" routerLinkActive="active">
            <label class="reserva">{{'visites.a-la-carta.button' | translate}}</label>
          </a>
        </div>

      </div>
    </div>
  </section>
  <!-- end experiencies -->



  <!-- També et pot interessar -->
  <section class="row section-grups" id="section-grups">
    <div class="col-md-10 col-lg-10 offset-md-1 offset-lg-1 col-xs-12 col-sm-12 col-12 pb-5 mobile-card-container">
      <div class="row justify-content-center animated inTop">
        <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center mb-4">
          <h2 class="titol">{{ 'experiencies.tambeinteressar' | translate}}</h2>
        </div>
      </div>

      <div class="row flex-nowrap flex-lg-wrap">
        <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'solsona-monumental']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.solsona-monumental.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/solsona-monumental-mini.jpg" alt="solsona monumental">
          </a> </div>
        <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'olius']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.olius.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/olius-mini.jpg" alt="sant esteve olius">
          </a></div>
        <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'pou-gel']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.pou-gel.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/pou-gel-mini.jpg" alt="quarto gegants Solsona">
          </a></div>
          <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
            <a [routerLink]="['/',currentLang,'gegants-carnaval-solsona']" routerLinkActive="active">
              <div class="div-h">
                <h3>{{ 'visites.gegants-carnaval-solsona.titol' | translate}}</h3>
              </div>
              <img class="img-responsive" src="assets/images/gegants-carnaval-solsona-mini.jpg" alt="gegants carnaval solsona">
            </a></div>
      </div>
    </div>
  </section>
  <!-- end et pot interessar -->


</div>