<!------   DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>
<!-- Enllaç canònic -->
<div *ngIf="canonicalLink">
  <link rel="canonical" appMoveToHead [attr.href]="canonicalLink | safeUrl" />
</div>
<!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
<meta appMoveToHead [attr.content]="metaContentKeywords |translate" name="keywords">
<meta appMoveToHead [attr.content]="metaContentDescription |translate" name="description">
<!-- FACEBOOK cal modificar el meta locale llengua dinàmic-->
<meta property="og:locale" appMoveToHead [attr.content]="ogLocale |translate">
<!-- Maximum 35 characters -->
<meta property="og:title" appMoveToHead [attr.content]="newTitle |translate">
<!-- Maximum 65 characters -->
<meta property="og:description" appMoveToHead [attr.content]="metaContentDescription |translate">
<meta property="og:url" appMoveToHead [attr.content]="canonicalLink | safeUrl">
<meta property="og:site_name" appMoveToHead [attr.content]="ogSiteName |translate">

<!-- TWITTER -->
<meta name="twitter:description" appMoveToHead [attr.content]="metaContentDescription |translate">
<meta name="twitter:title" appMoveToHead [attr.content]="newTitle |translate">
<!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
<link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="ca" />
<link rel="alternate" appMoveToHead [attr.href]="esLink | safeUrl" hreflang="es" />
<link rel="alternate" appMoveToHead [attr.href]="enLink | safeUrl" hreflang="en" />
<link rel="alternate" appMoveToHead [attr.href]="frLink | safeUrl" hreflang="fr" />
<link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="x-default" />
<!------   END DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>

<div class="container">
  <div class="row justify-content-center padding-menu titulos" id="titulos">
    <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center pb-5">
      <h1 class="objetivos-tl animated inLeft titol-IV">{{'visites-programades.titol' | translate}}</h1>
      <p class="pt-2 animated inRight subtitol-IV">{{'visites-programades.subtitol' | translate}}</p>
    </div>
  </div>
  <!-- SLIDER PRINCIPAL -->
  <div class="row justify-content-center padding-menu banner amaga-md-sm-xs" id="banner">
    <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center sin-padding animated inRight">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div class="swiper-slide" style="background: url(assets/images/solsona-monumental.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.solsona-monumental.titol' | translate}}</h1>
                <p>{{ 'visites.solsona-monumental.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'solsona-monumental']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.solsona-monumental.button' | translate}}</label></a>
              </div>
            </div>
          </div>
          <div class="swiper-slide" style="background: url(assets/images/gegants-carnaval-solsona.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.gegants-carnaval-solsona.titol' | translate}}</h1>
                <p>{{ 'visites.gegants-carnaval-solsona.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'gegants-carnaval-solsona']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.gegants-carnaval-solsona.button' | translate}}</label></a>
              </div>
            </div>
          </div>
          <div class="swiper-slide" style="background: url(assets/images/santuari-miracle.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.santuari-miracle.titol' | translate}}</h1>
                <p>{{ 'visites.santuari-miracle.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'santuari-miracle']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.santuari-miracle.button' | translate}}</label></a>
              </div>
            </div>
          </div>
          <div class="swiper-slide" style="background: url(assets/images/olius.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.olius.titol' | translate}}</h1>
                <p>{{ 'visites.olius.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'olius']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.olius.button' | translate}}</label></a>
              </div>
            </div>
          </div>
          <div class="swiper-slide" style="background: url(assets/images/pou-gel.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.pou-gel.titol' | translate}}</h1>
                <p>{{ 'visites.pou-gel.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'pou-gel']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.pou-gel.button' | translate}}</label></a>
              </div>
            </div>
          </div>
          <!-- <div class="swiper-slide" style="background: url(assets/images/quarto-gegants.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.quarto-gegants.titol' | translate}}</h1>
                <p>{{ 'visites.quarto-gegants.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'quarto-gegants']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.quarto-gegants.button' | translate}}</label></a>
              </div>
            </div>
          </div> -->
          <!-- <div class="swiper-slide" style="background: url(assets/images/orgue3.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.orgue.titol' | translate}}</h1>
                <p>{{ 'visites.orgue.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'orgue']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.orgue.button' | translate}}</label></a>
              </div>
            </div>
          </div> -->
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </div>
  <!-- end slider principal -->

  <!-- 3 ICONES -->
  <section class="row section-pc " id="section-pc">
    <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-8 offset-xl-2 mt-5">
      <div class="row">
        <div class="col-12 col-sm-4 text-center animated inLeft">
          <img class="icon-3" src="assets/icons/guia.png">
          <p class="prfs-pc">{{ 'home.raons.experiencies' | translate}}</p>
        </div>
        <div class="col-12 col-sm-4 text-center animated inTop">
          <img class="icon-3" src="assets/icons/mans.png">
          <p class="prfs-pc">{{ 'home.raons.estima' | translate}}</p>
        </div>
        <div class="col-12 col-sm-4 text-center animated inRight">
          <img class="icon-3" src="assets/icons/global.png">
          <p class="prfs-pc">{{ 'home.raons.xarxa' | translate}}</p>
        </div>
      </div>
    </div>
  </section>
  <!-- end 3 icones -->

  <!-- VISITES -->
  <section class="row section-visites" id="section-visites">
    <div class="col-md-10 col-lg-10 offset-md-1 offset-lg-1 col-xs-12 col-sm-12 col-12 pb-5 mobile-card-container">

      <div class="row flex-wrap pt-5">
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'solsona-monumental']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/solsona-monumental-mini.jpg" alt="solsona monumental">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.solsona-monumental.titol' | translate}}</h3>
            <p>{{'visites.solsona-monumental.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'solsona-monumental']" routerLinkActive="active">
            <label class="reserva">{{'visites.solsona-monumental.button' | translate}}</label>
          </a>
        </div>
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'gegants-carnaval-solsona']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/gegants-carnaval-solsona-mini.jpg" alt="Gegants bojos">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.gegants-carnaval-solsona.titol' | translate}}</h3>
            <p>{{'visites.gegants-carnaval-solsona.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'gegants-carnaval-solsona']" routerLinkActive="active">
            <label class="reserva">{{'visites.gegants-carnaval-solsona.button' | translate}}</label>
          </a>
        </div>
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'santuari-miracle']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/santuari-miracle-mini.jpg" alt="santuari del miracle">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.santuari-miracle.titol' | translate}}</h3>
            <p>{{'visites.santuari-miracle.subtitol-curt' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'santuari-miracle']" routerLinkActive="active">
            <label class="reserva">{{'visites.santuari-miracle.button' | translate}}</label>
          </a>
        </div>
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'olius']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/olius-mini.jpg" alt="sant esteve olius">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.olius.titol' | translate}}</h3>
            <p>{{'visites.olius.subtitol-curt' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'olius']" routerLinkActive="active">
            <label class="reserva">{{'visites.olius.button' | translate}}</label>
          </a>
        </div>
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'torre-vallferosa']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/torre-vallferosa-mini.jpg" alt="Torre de Vallferosa">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.torre-vallferosa.titol' | translate}}</h3>
            <p></p>
          </div>
          <a [routerLink]="['/',currentLang,'torre-vallferosa']" routerLinkActive="active">
            <label class="reserva">{{'visites.torre-vallferosa.button' | translate}}</label>
          </a>
        </div>
        <!-- <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'quarto-gegants']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/quarto-gegants-mini.jpg" alt="quarto gegants Solsona">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.quarto-gegants.titol' | translate}}</h3>
            <p>{{'visites.quarto-gegants.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'quarto-gegants']" routerLinkActive="active">
            <label class="reserva">{{'visites.quarto-gegants.button' | translate}}</label>
          </a>
        </div> -->
        <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'pou-gel']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/pou-gel-mini.jpg" alt="pou gel Solsona">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.pou-gel.titol' | translate}}</h3>
            <p>{{'visites.pou-gel.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'pou-gel']" routerLinkActive="active">
            <label class="reserva">{{'visites.pou-gel.button' | translate}}</label>
          </a>
        </div>
        <!-- <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 produc mx-auto">
          <a [routerLink]="['/',currentLang,'orgue']" routerLinkActive="active">
            <img class="img-responsive" src="assets/images/orgue-mini.jpg" alt="orgue catedral de Solsona">
          </a>
          <div class="div-IV">
            <h3>{{ 'visites.orgue.titol' | translate}}</h3>
            <p>{{'visites.orgue.subtitol' | translate}}</p>
          </div>
          <a [routerLink]="['/',currentLang,'orgue']" routerLinkActive="active">
            <label class="reserva">{{'visites.orgue.button' | translate}}</label>
          </a>
        </div> -->

        <!-- <div class="col-md-10 col-lg-4 col-xs-10 col-sm-10 col-10 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'museu-solsona']" routerLinkActive="active">
            <div class="div-IV">
              <h3>{{ 'visites.museu-solsona.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/museu-solsona-mini.jpg" alt="Museu Diocesà Solsona">
          </a></div> -->
      </div>
    </div>
  </section>
  <!-- end visites -->



  <!-- També et pot interessar -->
  <section class="row section-grups" id="section-grups">
    <div class="col-md-10 col-lg-10 offset-md-1 offset-lg-1 col-xs-12 col-sm-12 col-12 pb-5 mobile-card-container">
      <div class="row justify-content-center animated inTop">
        <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center mb-4">
          <h2 class="titol">{{ 'experiencies.tambeinteressar' | translate}}</h2>
        </div>
      </div>

      <div class="row flex-nowrap flex-lg-wrap">
        <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'nocturna-carnaval']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.nocturna-carnaval.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/nocturna-carnaval-mini.jpg" alt="olius">
          </a></div>
        <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'catedral']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.catedral.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/catedral-mini.jpg" alt="Catedral de Solsona">
          </a></div>
        <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'nucli-antic']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.nucli-antic.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/nucli-antic-mini.jpg" alt="necropolis">
          </a></div>
      </div>
    </div>
  </section>
  <!-- end et pot interessar -->


</div>