<!------   DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>
<!-- Enllaç canònic -->
<div *ngIf="canonicalLink">
  <link rel="canonical" appMoveToHead [attr.href]="canonicalLink | safeUrl" />
</div>
<!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
<meta appMoveToHead [attr.content]="metaContentKeywords |translate" name="keywords">
<meta appMoveToHead [attr.content]="metaContentDescription |translate" name="description">
<!-- FACEBOOK cal modificar el meta locale llengua dinàmic-->
<meta property="og:locale" appMoveToHead [attr.content]="ogLocale |translate">
<!-- Maximum 35 characters -->
<meta property="og:title" appMoveToHead [attr.content]="newTitle |translate">
<!-- Maximum 65 characters -->
<meta property="og:description" appMoveToHead [attr.content]="metaContentDescription |translate">
<meta property="og:url" appMoveToHead [attr.content]="canonicalLink | safeUrl">
<meta property="og:site_name" appMoveToHead [attr.content]="ogSiteName |translate">

<!-- TWITTER -->
<meta name="twitter:description" appMoveToHead [attr.content]="metaContentDescription |translate">
<meta name="twitter:title" appMoveToHead [attr.content]="newTitle |translate">
<!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
<link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="ca" />
<link rel="alternate" appMoveToHead [attr.href]="esLink | safeUrl" hreflang="es" />
<link rel="alternate" appMoveToHead [attr.href]="enLink | safeUrl" hreflang="en" />
<link rel="alternate" appMoveToHead [attr.href]="frLink | safeUrl" hreflang="fr" />
<link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="x-default" />
<!------   END DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>

<div class="container">
  <!-- SLIDER PRINCIPAL -->
  <div class="row justify-content-center padding-menu banner" id="banner">
    <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center sin-padding animated inRight">
      <div class="swiper-container padding-swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" style="background: url(assets/images/santuari-pinos-torre-ardevol.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.santuari-pinos-torre-ardevol.titol' | translate}}</h1>
                <p></p>
                <a [routerLink]="['/',currentLang,'santuari-pinos-torre-ardevol']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.santuari-pinos-torre-ardevol.button' | translate}}</label></a>
              </div>
            </div>
          </div>
          <div class="swiper-slide" style="background: url(assets/images/nocturna-carnaval.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.nocturna-carnaval.titol' | translate}}</h1>
                <p></p>
                <a [routerLink]="['/',currentLang,'nocturna-carnaval']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.nocturna-carnaval.button' | translate}}</label></a>
              </div>
            </div>
          </div>
          <div class="swiper-slide" style="background: url(assets/images/gegants-carnaval-solsona.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.gegants-carnaval-solsona.titol' | translate}}</h1>
                <p>{{ 'visites.gegants-carnaval-solsona.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'gegants-carnaval-solsona']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.gegants-carnaval-solsona.button' | translate}}</label></a>
              </div>
            </div>
          </div>
          <!-- <div class="swiper-slide" style="background: url(assets/images/nocturnes-solsona-educacio.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.nocturnes-solsona-educacio.titol' | translate}}</h1>
                <p>{{ 'visites.nocturnes-solsona-educacio.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'nocturnes-solsona-educacio']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.nocturnes-solsona-educacio.button' | translate}}</label></a>
              </div>
            </div>
          </div> -->
          <div class="swiper-slide" style="background: url(assets/images/solsona-monumental.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.solsona-monumental.titol' | translate}}</h1>
                <p>{{ 'visites.solsona-monumental.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'solsona-monumental']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.solsona-monumental.button' | translate}}</label></a>
              </div>
            </div>
          </div>
          <div class="swiper-slide" style="background: url(assets/images/pou-gel.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.pou-gel.titol' | translate}}</h1>
                <p>{{ 'visites.pou-gel.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'pou-gel']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.pou-gel.button' | translate}}</label></a>
              </div>
            </div>
          </div>
          <div class="swiper-slide" style="background: url(assets/images/santuari-miracle.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.santuari-miracle.titol' | translate}}</h1>
                <p>{{ 'visites.santuari-miracle.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'santuari-miracle']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.santuari-miracle.button' | translate}}</label></a>
              </div>
            </div>
          </div>
          <div class="swiper-slide" style="background: url(assets/images/olius.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.olius.titol' | translate}}</h1>
                <p>{{ 'visites.olius.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'olius']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.olius.button' | translate}}</label></a>
              </div>
            </div>
          </div>
          <!--<div class="swiper-slide" style="background: url(assets/images/nocturnes-olius.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.nocturnes-olius.titol' | translate}}</h1>
                <p>{{ 'visites.nocturnes-olius.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'nocturnes-olius']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.nocturnes-olius.button' | translate}}</label></a>
              </div>
            </div>
          </div>-->
          <!-- <div class="swiper-slide" style="background: url(assets/images/quarto-gegants.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.quarto-gegants.titol' | translate}}</h1>
                <p>{{ 'visites.quarto-gegants.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'quarto-gegants']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.quarto-gegants.button' | translate}}</label></a>
              </div>
            </div>
          </div> -->
          <div class="swiper-slide" style="background: url(assets/images/celler-miracle.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.celler-miracle.titol' | translate}}</h1>
                <p>{{ 'visites.celler-miracle.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'celler-miracle']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.celler-miracle.button' | translate}}</label></a>
              </div>
            </div>
          </div>
          <div class="swiper-slide" style="background: url(assets/images/orgue4.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.orgue.titol' | translate}}</h1>
                <p>{{ 'visites.orgue.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'orgue']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.orgue.button' | translate}}</label></a>
              </div>
            </div>
          </div>
          <div class="swiper-slide" style="background: url(assets/images/senderisme-castellvell.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.senderisme-castellvell.titol' | translate}}</h1>
                <p>{{ 'visites.senderisme-castellvell.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'senderisme-castellvell']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.senderisme-castellvell.button' | translate}}</label></a>
              </div>
            </div>
          </div>
          <div class="swiper-slide" style="background: url(assets/images/busa.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.busa.titol' | translate}}</h1>
                <p>{{ 'visites.busa.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'busa']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.busa.button' | translate}}</label></a>
              </div>
            </div>
          </div>
          <!-- <div class="swiper-slide" style="background: url(assets/images/tasta-solsona.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.tasta-solsona.titol' | translate}}</h1>
                <p>{{ 'visites.tasta-solsona.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'tasta-solsona']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.tasta-solsona.button' | translate}}</label></a>
              </div>
            </div>
          </div> -->
          <div class="swiper-slide" style="background: url(assets/images/a-la-carta.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.a-la-carta.titol' | translate}}</h1>
                <p>{{ 'visites.a-la-carta.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'a-la-carta']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.a-la-carta.button' | translate}}</label></a>
              </div>
            </div>
          </div>
          <div class="swiper-slide" style="background: url(assets/images/campanar.jpg);">
            <div class="row justify-content-center align-items-center h-100 sin-margen">
              <div class="col-md-6 col-12 col-sm-12 col-xs-12 a-center sin-padding">
                <h1>{{ 'visites.campanar.titol' | translate}}</h1>
                <p>{{ 'visites.campanar.subtitol' | translate}}</p>
                <a [routerLink]="['/',currentLang,'campanar']" routerLinkActive="active">
                  <label class="reserva">{{ 'visites.campanar.button' | translate}}</label></a>
              </div>
            </div>
          </div>
        </div>
        <!-- Add Pagination -->
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </div>
  <!-- end slider principal -->

  <!-- 3 ICONES -->
  <section class="row section-pc " id="section-pc">
    <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 col-xl-8 offset-xl-2 mt-5">
      <div class="row">
        <div class="col-12 col-sm-4 text-center animated inLeft">
          <img class="icon-3" src="assets/icons/guia.png">
          <p class="prfs-pc">{{ 'home.raons.experiencies' | translate}}</p>
        </div>
        <div class="col-12 col-sm-4 text-center animated inTop">
          <img class="icon-3" src="assets/icons/mans.png">
          <p class="prfs-pc">{{ 'home.raons.estima' | translate}}</p>
        </div>
        <div class="col-12 col-sm-4 text-center animated inRight">
          <img class="icon-3" src="assets/icons/global.png">
          <p class="prfs-pc">{{ 'home.raons.xarxa' | translate}}</p>
        </div>
      </div>
    </div>
  </section>
  <!-- end 3 icones -->


  <!-- VISITES PROGRAMADES -->
  <section class="row section-experiencies" id="section-experiencies">
    <div class="col-md-10 col-lg-10 offset-md-1 offset-lg-1 col-xs-12 col-sm-12 col-12 pb-5 mobile-card-container">
      <div class="row justify-content-center animated inTop">
        <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center mb-4">
          <h2 class="titol">{{ 'home.titols.visites-guiades' | translate}}</h2>
        </div>
      </div>

      <div class="row flex-nowrap flex-lg-wrap">
        <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'solsona-monumental']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.solsona-monumental.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/solsona-monumental-mini.jpg" alt="solsona monumental">
          </a></div>
        <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'gegants-carnaval-solsona']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.gegants-carnaval-solsona.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/gegants-carnaval-solsona-mini.jpg" alt="gegants bojos">
          </a></div>
        <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'santuari-miracle']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.santuari-miracle.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/santuari-miracle-mini.jpg" alt="santuari del miracle">
          </a></div>
        <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'olius']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.olius.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/olius-mini.jpg" alt="sant esteve olius">
          </a></div>
        <!-- <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'quarto-gegants']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.quarto-gegants.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/quarto-gegants-mini.jpg" alt="quarto gegants Solsona">
          </a></div> -->
        <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'pou-gel']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.pou-gel.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/pou-gel-mini.jpg" alt="Pou de gel de Solsona">
          </a></div>


        <!-- <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'museu-solsona']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.museu-solsona.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/museu-solsona-mini.jpg" alt="Museu Diocesà Solsona">
          </a></div> -->
      </div>
      <div class="row oculta-mobil">
        <div class="col-12">
          <div class="text-center pb-4 pt-2">
            <a [routerLink]="['/',currentLang,'visites-programades']" routerLinkActive="active">{{ 'home.mes-visites' | translate}}</a>
          </div>
        </div>
      </div>
      <div class="row mostra-mobil">
        <div class="col-12">
          <div class="text-center pb-4 pt-2">
            <a [routerLink]="['/',currentLang,'visites-programades']" routerLinkActive="active">
              <label class="reserva">{{ 'home.mes-visites' | translate}}</label>
            </a></div>
        </div>
      </div>
    </div>
    <!-- end visites -->

    <!-- EXPERIENCIES -->
    <div class="col-md-10 col-lg-10 offset-md-1 offset-lg-1 col-xs-12 col-sm-12 col-12 pb-5 mobile-card-container">
      <div class="row justify-content-center animated inTop">
        <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center mb-4">
          <h2 class="titol">{{ 'home.titols.experiencies' | translate}}</h2>
        </div>
      </div>

      <div class="row flex-nowrap flex-lg-wrap">
        <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'santuari-pinos-torre-ardevol']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.santuari-pinos-torre-ardevol.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/santuari-pinos-torre-ardevol-mini.jpg" alt="deu anys">
          </a></div>
          <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
            <a [routerLink]="['/',currentLang,'nocturna-carnaval']" routerLinkActive="active">
              <div class="div-h">
                <h3>{{ 'visites.nocturna-carnaval.titol' | translate}}</h3>
              </div>
              <img class="img-responsive" src="assets/images/nocturna-carnaval-mini.jpg" alt="Carnaval">
            </a></div>
        <!--<div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'nocturnes-olius']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.nocturnes-olius.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/nocturnes-olius-mini.jpg" alt="olius">
          </a></div> -->
        <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'catedral']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.catedral.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/catedral-mini.jpg" alt="Catedral de Solsona">
          </a></div>
        <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'nucli-antic']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.nucli-antic.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/nucli-antic-mini.jpg" alt="nucli antic">
          </a></div>
        <!--<div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'busa']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.busa.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/busa-mini.jpg" alt="Serra de Busa">
          </a></div>-->
        <!-- <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'tasta-solsona']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.tasta-solsona.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/tasta-solsona-mini.jpg" alt="tasta solsona">
          </a></div> -->

      </div>
      <div class="row oculta-mobil">
        <div class="col-12">
          <div class="text-center pb-4 pt-2">
            <a [routerLink]="['/',currentLang,'experiencies']" routerLinkActive="active">{{ 'home.mes-experiencies' | translate}}</a>
          </div>
        </div>
      </div>
      <div class="row mostra-mobil">
        <div class="col-12">
          <div class="text-center pb-4 pt-2">
            <a [routerLink]="['/',currentLang,'experiencies']" routerLinkActive="active">
              <label class="reserva">{{ 'home.mes-experiencies' | translate}}</label>
            </a></div>
        </div>
      </div>
    </div>
  </section>
  <!-- end visites -->

  <!-- OPINIONS -->
  <section class="row section-visitants" id="section-visitants">
    <div class="col-12 col-md-10 offset-md-1 pt-5 pb-5">
      <div class="row">
        <div class="col-12">
          <h3 class="question">{{ 'home.titols.opina' | translate}}</h3>
        </div>
        <div class="swiper-container-opina col-md-10 col-lg-8 col-xl-6 col-12 mx-auto">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="row justify-content-center align-items-center h-100">
                <div class="col-12">
                  <h4 class="nom">{{ 'home.opinio.12.nom' | translate}}</h4>
                  <p class="tx-vis">{{ 'home.opinio.12.text' | translate}}</p>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="row justify-content-center align-items-center h-100">
                <div class="col-12">
                  <h4 class="nom">{{ 'home.opinio.6.nom' | translate}}</h4>
                  <p class="tx-vis">{{ 'home.opinio.6.text' | translate}}</p>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="row justify-content-center align-items-center h-100">
                <div class="col-12">
                  <h4 class="nom">{{ 'home.opinio.10.nom' | translate}}</h4>
                  <p class="tx-vis">{{ 'home.opinio.10.text' | translate}}</p>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="row justify-content-center align-items-center h-100">
                <div class="col-12">
                  <h4 class="nom">{{ 'home.opinio.11.nom' | translate}}</h4>
                  <p class="tx-vis">{{ 'home.opinio.11.text' | translate}}</p>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="row justify-content-center align-items-center h-100">
                <div class="col-12">
                  <h4 class="nom">{{ 'home.opinio.1.nom' | translate}}</h4>
                  <p class="tx-vis">{{ 'home.opinio.1.text' | translate}}</p>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="row justify-content-center align-items-center h-100">
                <div class="col-12">
                  <h4 class="nom">{{ 'home.opinio.9.nom' | translate}}</h4>
                  <p class="tx-vis">{{ 'home.opinio.9.text' | translate}}</p>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="row justify-content-center align-items-center h-100">
                <div class="col-12">
                  <h4 class="nom">{{ 'home.opinio.7.nom' | translate}}</h4>
                  <p class="tx-vis">{{ 'home.opinio.7.text' | translate}}</p>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="row justify-content-center align-items-center h-100">
                <div class="col-12">
                  <h4 class="nom">{{ 'home.opinio.8.nom' | translate}}</h4>
                  <p class="tx-vis">{{ 'home.opinio.8.text' | translate}}</p>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="row justify-content-center align-items-center h-100">
                <div class="col-12">
                  <h4 class="nom">{{ 'home.opinio.2.nom' | translate}}</h4>
                  <p class="tx-vis">{{ 'home.opinio.2.text' | translate}}</p>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="row justify-content-center align-items-center h-100">
                <div class="col-12">
                  <h4 class="nom">{{ 'home.opinio.3.nom' | translate}}</h4>
                  <p class="tx-vis">{{ 'home.opinio.3.text' | translate}}</p>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="row justify-content-center align-items-center h-100">
                <div class="col-12">
                  <h4 class="nom">{{ 'home.opinio.4.nom' | translate}}</h4>
                  <p class="tx-vis">{{ 'home.opinio.4.text' | translate}}</p>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="row justify-content-center align-items-center h-100">
                <div class="col-12">
                  <h4 class="nom">{{ 'home.opinio.5.nom' | translate}}</h4>
                  <p class="tx-vis">{{ 'home.opinio.5.text' | translate}}</p>
                </div>
              </div>
            </div>

          </div>
          <div class="swiper-pagination"></div>
        </div>
        <div class="col-12 pt-5">
          <div class="row justify-content-center align-items-center">
            <div class="text-center">
              <a target="_blank" (click)="clickTimestamp('review', 'https://g.page/visitasolsona/review?gm')" href="https://g.page/visitasolsona/review?gm">
                <input class="vst-button" type="button" value="{{ 'home.opinio.digues' | translate}}"></a>
            </div>
          </div>
          <div class="row justify-content-center align-items-center">
            <div class="text-center pb-4 pt-3">
              <a class="vst-opc" target="_blank"
                href="https://www.google.com/search?q=solsona+experience&oq=solsona+expe&aqs=chrome.0.69i59j69i57j35i39j69i60l2j69i61j69i65l2.1872j0j4&sourceid=chrome&ie=UTF-8#lrd=0x12a5c9fde9c26119:0x8c96d1496abb0e25,1,,,">
                {{ 'home.opinio.veure' | translate}}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- end opinions -->

  <!-- GRUPS -->
  <section class="row section-grups" id="section-grups">
    <div class="col-md-10 col-lg-10 offset-md-1 offset-lg-1 col-xs-12 col-sm-12 col-12 pb-5 mobile-card-container">
      <div class="row justify-content-center animated inTop">
        <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center mb-4">
          <h2 class="titol">{{ 'home.titols.grups' | translate}}</h2>
        </div>
      </div>

      <div class="row flex-nowrap flex-lg-wrap">
        <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'solsona-monumental']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.solsona-monumental.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/solsona-monumental-mini.jpg" alt="solsona monumental">
          </a>
        </div>
        <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'santuari-miracle']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.santuari-miracle.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/santuari-miracle-mini.jpg" alt="santuari del miracle">
          </a></div>
        <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'olius']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.olius.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/olius-mini.jpg" alt="sant esteve olius">
          </a></div>
        <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
          <a [routerLink]="['/',currentLang,'pou-gel']" routerLinkActive="active">
            <div class="div-h">
              <h3>{{ 'visites.pou-gel.titol' | translate}}</h3>
            </div>
            <img class="img-responsive" src="assets/images/pou-gel-mini.jpg" alt="pou de gel">
          </a></div>
      </div>
      <div class="row oculta-mobil">
        <div class="col-12">
          <div class="text-center pb-4 pt-2">
            <a [routerLink]="['/',currentLang,'grups-particulars']" routerLinkActive="active">{{ 'home.veure-opcions' | translate}}</a>
          </div>
        </div>
      </div>
      <div class="row mostra-mobil">
        <div class="col-12">
          <div class="text-center pb-4 pt-2">
            <a [routerLink]="['/',currentLang,'grups-particulars']" routerLinkActive="active">
              <label class="reserva">{{ 'home.veure-opcions' | translate}}</label>
            </a></div>
        </div>
      </div>
    </div>
  </section>
  <!-- end grups -->

  <!-- VISITES A MIDA -->
  <section class="row section-mida" id="section-mida">
    <div class="col-12 col-md-10 offset-md-1 pt-5">
      <div class="row section-pc">
        <div class="col-10 offset-1 col-sm-8 offset-sm-2 col-md-8 offset-md-2 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
          <h3 class="question">{{ 'home.titols.alacarta' | translate}}</h3>
          <p class="tx-mida">{{ 'home.alacarta.subtitol' | translate}}</p>
        </div>
        <div class="col-12 col-xl-10 offset-xl-1">
          <div class="row">
            <div class="col-12 col-md-4 text-center animated inLeft">
              <img class="icon-3" src="assets/icons/grup.png">
              <p class="prfs-mida">{{ 'home.alacarta.grups' | translate}}</p>
            </div>
            <div class="col-12 col-md-4 text-center animated inTop">
              <img class="icon-3" src="assets/icons/calendari.png">
              <p class="prfs-mida">{{ 'home.alacarta.horaris' | translate}}</p>
            </div>
            <div class="col-12 col-md-4 text-center animated inRight">
              <img class="icon-3" src="assets/icons/smile.png">
              <p class="prfs-mida">{{ 'home.alacarta.guies' | translate}}</p>
            </div>
            <div class="col-12 text-center escriu animated inBottom">
              <a (click)="clickTimestamp('booking', 'https://solsonaexperience/contacte')" [routerLink]="['/',currentLang,'contacte']" routerLinkActive="active"><input class="vst-button" type="button"
                  value="{{ 'home.alacarta.escriu' | translate}}"></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- end visites a mida -->

  <!-- Vídeo -->
  <div class="row justify-content-center" id="video">
    <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center pt-5 pb-5">
      <iframe *ngIf="video_ca" class="IVframe" src="https://www.youtube.com/embed/YU-vtFHWBxk" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
      </iframe>
      <iframe *ngIf="video_es" class="IVframe" src="https://www.youtube.com/embed/G8pG062VK1g" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
      </iframe>
      <iframe *ngIf="video_en" class="IVframe" src="https://www.youtube.com/embed/ZJP8BQjLagQ" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
      </iframe>
      <iframe *ngIf="video_fr" class="IVframe" src="https://www.youtube.com/embed/YHpF5WgarfE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
      </iframe>
    </div>
  </div>
  <!-- end vídeo -->
</div>