<!------   DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>
<!-- Enllaç canònic -->
<div *ngIf="canonicalLink">
    <link rel="canonical" appMoveToHead [attr.href]="canonicalLink | safeUrl" />
  </div>
  <!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
  <meta appMoveToHead [attr.content]="metaContentKeywords |translate" name="keywords">
  <meta appMoveToHead [attr.content]="metaContentDescription |translate" name="description">
  <!-- FACEBOOK cal modificar el meta locale llengua dinàmic-->
  <meta property="og:locale" appMoveToHead [attr.content]="ogLocale |translate">
  <!-- Maximum 35 characters -->
  <meta property="og:title" appMoveToHead [attr.content]="newTitle |translate">
  <!-- Maximum 65 characters -->
  <meta property="og:description" appMoveToHead [attr.content]="metaContentDescription |translate">
  <meta property="og:url" appMoveToHead [attr.content]="canonicalLink | safeUrl">
  <meta property="og:site_name" appMoveToHead [attr.content]="ogSiteName |translate">
  
  <!-- TWITTER -->
  <meta name="twitter:description" appMoveToHead [attr.content]="metaContentDescription |translate">
  <meta name="twitter:title" appMoveToHead [attr.content]="newTitle |translate">
  <!-- Que canviï per codi per a cada subpàgina canònica, i idioma -->
  <link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="ca" />
  <link rel="alternate" appMoveToHead [attr.href]="esLink | safeUrl" hreflang="es" />
  <link rel="alternate" appMoveToHead [attr.href]="enLink | safeUrl" hreflang="en" />
  <link rel="alternate" appMoveToHead [attr.href]="frLink | safeUrl" hreflang="fr" />
  <link rel="alternate" appMoveToHead [attr.href]="caLink | safeUrl" hreflang="x-default" />
  <!------   END DYNAMIC HEAD ELEMENTS       SEO ELEMENTS  ------>
  
  <div class="container">
  
    <!-- SLIDER PRINCIPAL -->
    <div class="row justify-content-center padding-menu banner" id="banner">
      <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center sin-padding animated inRight">
        <div class="swiper-container-fade padding-swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide active" style="background: url(assets/images/torre-vallferosa.jpg);">
  
            </div>
          </div>
          <div class="swiper-pagination"></div>
        </div>
      </div>
    </div>
    <!-- end slider principal -->
  
    <!-- TÍTOL PRODUCTE -->
    <div class="row justify-content-center padding-menu titulos" id="titulos">
      <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center pt-5 pb-5">
        <h1 class="objetivos-tl animated inLeft titol-IV">{{'visites.torre-vallferosa.titol' | translate}}</h1>
        <!--<p class="pt-2 animated inRight subtitol-IV">{{'visites.torre-vallferosa.subtitol' | translate}}</p>-->
      </div>
    </div>
    <!-- end títol -->
  
<!-- DESCRIPCIÓ PRODUCTE -->
<div class="row justify-content-center reserves" id="reserves">
  <div class="col-md-10 col-lg-10 col-xs-12 col-sm-12 col-12 sin-padding mt-3 pt-5 pb-5 ">
    <div class="row sin-margen">
      <div class="col-md-6 col-lg-6 col-xs-10 col-sm-10 col-10 animated inLeft pb-5 mx-auto">
        <div class="pb-2">{{'visites.torre-vallferosa.descripcio' | translate}}</div>
        <div class="pt-2">{{'visites.torre-vallferosa.descripcio1' | translate}}</div><br>
        <div class="pt-2" style="font-style:italic;">{{'visites.torre-vallferosa.descripcio2' | translate}}</div>
        <br>
        <b><a target=_blank href="{{'visites.torre-vallferosa.samara-link' | translate}}"> {{'visites.torre-vallferosa.samara' | translate}}</a></b>
        <br><br><div class="pt-2" style="font-style:italic;"> {{'visites.torre-vallferosa.descripcio3' | translate}}</div>
      </div>
      <div class="col-md-6 col-lg-6 col-xs-10 col-sm-10 col-10 a-center animated inRight pb-5 mx-auto">
        <b>{{'visites.durada' | translate}}:</b> {{'visites.torre-vallferosa.durada' | translate}}<br>
        <b>{{'visites.tipus' | translate}}:</b> {{'visites.torre-vallferosa.tipus' | translate}}<br>
        <b>{{'visites.preu' | translate}}:</b> {{'visites.torre-vallferosa.preu' | translate}}<br>
        <!-- <b>{{'visites.descomptes' | translate}}:</b> {{'visites.torre-vallferosa.descomptes' | translate}}<br> -->
        <br>
        <b>{{'visites.sortida' | translate}}:</b><a target=_blank href="{{'visites.torre-vallferosa.sortida-link' | translate}}"> {{'visites.torre-vallferosa.sortida' | translate}}</a><br>
        <!-- <b>{{'visites.aforament' | translate}}:</b> {{'visites.torre-vallferosa.aforament' | translate}}<br> -->
        <br>
        <b>{{'visites.organitza' | translate}}:</b> {{'visites.torre-vallferosa.organitza' | translate}}<br>

        <!-- <b>{{'visites.dificultat' | translate}}:</b> {{'visites.torre-vallferosa.dificultat' | translate}}<br> -->
        <!-- <br>
      <b>{{'visites.organitza' | translate}}:</b> {{'visites.torre-vallferosa.organitza' | translate}}<br>
    -->
        <br>
        <a target="_blank" (click)="clickTimestamp('booking', 'https://entradessolsones.com/activitat/visita-guiada-a-la-torre-de-vallferosa')"
          href="https://entradessolsones.com/activitat/visita-guiada-a-la-torre-de-vallferosa"><label class="reserva">{{'visites.compra' | translate}}</label></a><br><br>
        <a class="vst-opc" [routerLink]="['/',currentLang,'contacte']" routerLinkActive="active">{{'visites.mesinfo' | translate}}</a><br>
      </div>
    </div>
  </div>
</div>
<!-- end descripció produtcte -->
  
    <!-- OPINIONS -->
    <!--<section class="row section-visitants" id="section-visitants">
      <div class="col-12 col-md-10 offset-md-1 pt-5 pb-5">
        <div class="row">
          <div class="col-12">
            <h3 class="question">{{ 'home.titols.opina' | translate}}</h3>
          </div>
          <div class="swiper-container-opina col-md-10 col-lg-8 col-xl-6 col-12 mx-auto">
            <div class="swiper-wrapper">
              <div class="swiper-slide">
                <div class="row justify-content-center align-items-center h-100">
                  <div class="col-12">
                    <h4 class="nom">{{ 'visites.torre-vallferosa.opinio.1.nom' | translate}}</h4>
                    <p class="tx-vis">{{ 'visites.torre-vallferosa.opinio.1.text' | translate}}</p>
                  </div>
                </div>
              </div>
  
            </div>
            <div class="swiper-pagination"></div>
          </div>
          <div class="col-12 pt-5">
            <div class="row justify-content-center align-items-center">
              <div class="text-center">
                <a target="_blank" (click)="clickTimestamp('review', 'https://g.page/visitasolsona/review?gm')" href="https://g.page/visitasolsona/review?gm">
                  <input class="vst-button" type="button" value="{{ 'home.opinio.digues' | translate}}"></a>
              </div>
            </div>
            <div class="row justify-content-center align-items-center">
              <div class="text-center pb-4 pt-3">
                <a class="vst-opc" target="_blank"
                  href="https://www.google.com/search?q=solsona+experience&oq=solsona+expe&aqs=chrome.0.69i59j69i57j35i39j69i60l2j69i61j69i65l2.1872j0j4&sourceid=chrome&ie=UTF-8#lrd=0x12a5c9fde9c26119:0x8c96d1496abb0e25,1,,,">
                  {{ 'home.opinio.veure' | translate}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- end opinions -->
  
    <!-- També et pot interessar -->
    <section class="row section-grups" id="section-grups">
      <div class="col-md-10 col-lg-10 offset-md-1 offset-lg-1 col-xs-12 col-sm-12 col-12 pb-5 mobile-card-container">
        <div class="row justify-content-center animated inTop">
          <div class="col-md-12 col-lg-12 col-xs-12 col-sm-12 col-12 a-center mb-4">
            <h2 class="titol">{{ 'experiencies.tambeinteressar' | translate}}</h2>
          </div>
        </div>
  
        <div class="row flex-nowrap flex-lg-wrap">
          <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
            <a [routerLink]="['/',currentLang,'solsona-monumental']" routerLinkActive="active">
              <div class="div-h">
                <h3>{{ 'visites.solsona-monumental.titol' | translate}}</h3>
              </div>
              <img class="img-responsive" src="assets/images/solsona-monumental-mini.jpg" alt="solsona monumental">
            </a>
          </div>
          <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
            <a [routerLink]="['/',currentLang,'olius']" routerLinkActive="active">
              <div class="div-h">
                <h3>{{ 'visites.olius.titol' | translate}}</h3>
              </div>
              <img class="img-responsive" src="assets/images/olius-mini.jpg" alt="conjunt monumental olius">
            </a></div>
          <div class="col-md-7 col-lg-4 col-xs-7 col-sm-7 col-7 tambien mx-auto">
            <a [routerLink]="['/',currentLang,'gegants-carnaval-solsona']" routerLinkActive="active">
              <div class="div-h">
                <h3>{{ 'visites.gegants-carnaval-solsona.titol' | translate}}</h3>
              </div>
              <img class="img-responsive" src="assets/images/gegants-carnaval-solsona-mini.jpg" alt="quarto gegants Solsona">
            </a></div>
        </div>
      </div>
    </section>
    <!-- end et pot interessar -->
  
  
  </div>